import { mapActions, mapGetters } from 'vuex'

const Modal = () => import(/* webpackChunkName = "c-modal" */'@/components/Modal')

export default {
  name: 'AddWorshipForm',
  Components: {
    Modal
  },
  data () {
    return {
      title: '',
      info: '',
      youtubeVideoId: '',
      imageUrl: '',
      sequence: 0
    }
  },
  props: {
    worshipData: {
      type: Object,
      default: () => {
      }
    }
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapGetters('user', ['token']),
    requestData () {
      return {
        sequence: this.sequence,
        worship: {
          title: this.title,
          info: this.info,
          youtubeVideoId: this.youtubeVideoId,
          imageUrl: this.imageUrl,
          extendedData: null,
          token: this.token
        }
      }
    }
  },
  methods: {
    ...mapActions('worship', ['getWorshipsAction', 'editWorshipAction']),
    initPage () {
      this.title = this.worshipData.title
      this.info = this.worshipData.info
      this.youtubeVideoId = this.worshipData.youtubeVideoId
      this.sequence = this.worshipData.sequence
      this.imageUrl = this.worshipData.imageUrl
    },
    close () {
      this.$emit('close')
    },
    handleSubmit () {
      this.editWorshipAction({
        data: this.requestData,
        success: this.handleSuccessSubmit
      })
    },
    handleSuccessSubmit () {
      this.$swal({
        icon: 'success',
        title: 'Success Submit',
        showConfirmButton: false,
        timer: 1500
      })
      this.getWorshipsAction({
        success: () => {}
      })
      this.close()
    }
  }
}
